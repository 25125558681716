import type { Composer } from '@nuxtjs/i18n/dist/runtime/composables';
import { DISCOUNT_TYPES } from '#root/shared/config/discount-config';

const promoTitle = (promo: any, i18n: Composer, hideCode = false) => {
  if (!promo) {
    return '';
  }

  const { $formatPrice } = useNuxtApp();

  const { type, amount, code, isCombinable } = promo;
  let formattedDiscount = '';

  const amountWithCurrency =
    type !== DISCOUNT_TYPES.percentage ? $formatPrice(amount) : null;

  switch (type) {
    case DISCOUNT_TYPES.percentage:
      formattedDiscount = `${amount}%`;
      break;
    case DISCOUNT_TYPES.per_item:
      formattedDiscount = `${amountWithCurrency}${i18n.t('promo.per_item')}`;
      break;
    case DISCOUNT_TYPES.per_total:
      formattedDiscount = `${amountWithCurrency}`;
      break;
    default:
      break;
  }

  return `${i18n.t('promo.discount')} ${formattedDiscount} ${
    !isCombinable ? `${i18n.t('promo.non_discounted_products')} ` : ''
  }${hideCode ? '' : `(${code})`}`;
};

export { promoTitle };
